<script>
//import vue2Dropzone from "vue2-dropzone";
//import Multiselect from "vue-multiselect";
import {
  required,
} from "vuelidate/lib/validators";

import Swal from "sweetalert2";
import {VueSignaturePad} from 'vue-signature-pad';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import OrdenService from "@/services/OrdenService";
import RetirementService from "@/services/RetirementService";
import HelperJS from "@/functions/general";
import moment from "moment";
const helper = new  HelperJS();
import {  mapState } from "vuex";

const ordenService = new OrdenService();
const retirementService = new RetirementService();
//const accountService = new AccountService();

//import "vue2-dropzone/dist/vue2Dropzone.min.css";
//import "vue-multiselect/dist/vue-multiselect.min.css";

/**
 * Add-product component
 */
export default {
  page: {
    title: "Pago interés",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    //vueDropzone: vue2Dropzone,
    //Multiselect,
    VueSignaturePad,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Pago interés",
      infoPage:{
        currencys:['MXN', 'USD'],
        view: 'add',
        routeBack: '/intereses/list',
        method_payments: [
          {
            id:1,
            text: 'Efectivo'
          },
          {
            id:2,
            text: 'Transferencia' 
          },
          {
            id:3,
            text: 'Cheque' 
          },
        ],
      },
      infoData:{
        account_name: null,
        client_name: null,
      },

      objectData:{
        name: null,
        account_id: null,
        orden_id: null,
        client_id: null,
        user_id: null,
        currency: null,
        amount: null,
        method_payment: 1,
        date: null,
        start_date: null,  
        image: null,
        date_payment: null,
        status: null,
        comment: null,
        id: null
      },



  

      items: [
        {
          text: "Inicio"
        },
        {
          text: "Add Product",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
      },
      value1: null,
      submitted: false,
      options: [
        "High Quality",
        "Leather",
        "Notifications",
        "Sizes",
        "Different Color",
      ],
    };
  },
  validations: {
      objectData: {
        name: {
          required,
        },
        method_payment: {
          required,
        }
      },
    },
  middleware: "authentication",
    computed: {
    /**
     * Total no. of records
     */
  ...mapState('auth', ['currentUser']),
  
  },
  created(){

    this.chargeItems();
  },
 methods: {
  undo() {
      this.$refs.signaturePad.clearSignature();
       //const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      //alert("Open DevTools see the save data.");
     /**console.log(isEmpty);
      console.log(data);*/
    },
    
    formatMoney(value){
      return helper.formatMoney(value);

    },
    formatDate(value, type='short'){
      return helper.formatDate(value, type);

    },
    chargeItems(){

      if(this.$route.name==='interesesPayment'){
        this.infoPage.view = 'payment';
        this.items[1].text = 'Crear pago';
        ordenService.getById(this.$route.params.id).then(async response=>{
          let data = response.data.orden;
         /*for(let prop in data){
           this.objectData[prop] = data[prop];
         }*/
         this.objectData.date = data.cut_date;
         this.objectData.date_payment = moment().format('YYYY-MM-DD');
         this.objectData.amount = data.amount;
         this.objectData.orden_id = data.id;
         this.objectData.account_id = data.account_id;
         this.objectData.client_id = data.client_id;
         this.objectData.start_date = data.start_date;
         this.objectData.status = 1;
        this.objectData.user_id = this.currentUser.id;

         this.infoData.client_name = data.client.full_name;
         this.infoData.account_name = data.account.name;
         this.objectData.currency = data.account.currency;
        });
      }
      if(this.$route.name==='interesesInfo'){
        this.infoPage.view = 'info';
        this.items[1].text = 'Información pago';
        retirementService.getByOrdenId(this.$route.params.id).then(async response=>{  
          let data = response.data.retiro;

          this.objectData.date = data.date;
          this.objectData.date_payment = data.method_payment;
          this.objectData.amount = data.amount;
          this.objectData.orden_id = data.id;
          this.objectData.account_id = data.account_id;
          this.objectData.client_id = data.client_id;
          this.objectData.start_date = data.start_date;
          this.objectData.status = data.status;
          this.objectData.user_id = this.currentUser.id;
          this.objectData.currency = data.currency;
          this.objectData.image = data.image;

          this.objectData.method_payment = data.method_payment;
          this.objectData.name = data.name;
          this.objectData.comment = data.comment;
          this.objectData.id = data.id;
        
         this.infoData.client_name = data.client.full_name;
         this.infoData.account_name = data.account.name;
          this.objectData.currency = data.currency;

        });



      }
      

    },
    async formSubmit() {
      //console.log('enviado', this.$v);
      this.submitted = true;
      if(this.$v.$error===false &&  this.$v.$invalid===false){
        console.log('validado');
        let params = {...this.objectData}
        
        if( this.infoPage.view === 'payment'){

           const { data } = this.$refs.signaturePad.saveSignature();

     
           params.image = data;

          await retirementService.create(params)
          

         /* let requestResponse = await clientService.update(params);
          requestResponse = requestResponse.data.client;
          console.log('requestResponse', );
          let idx = 0;

          while(idx<this.accounts.length){
            let element =this.accounts[idx];
            //this.accounts.forEach(async element => {
            element.start_date = moment().format('YYYY-MM-DD');
            element.client_id = requestResponse.id;
            if(element.id===null){
              
              element.amount = 0;
              await accountService.create(element)

            }else{
              await accountService.update(element)
            }
            //});
            idx++;
          }*/
           
        }else{
          delete params.id;
          
          /*let requestResponse = await clientService.create(params);
          requestResponse = requestResponse.data.client;
          //console.log('requestResponse', );
          let idx = 0;

          while(idx<this.accounts.length){
            let element =this.accounts[idx];
            delete element.id;
            element.amount = 0;
            element.start_date = moment().format('YYYY-MM-DD');
            element.client_id = requestResponse.id;
            await accountService.create(element)
            idx++;
          }*/
          
        }
          this.successForm(this.infoPage.routeBack);
      }
      
      // stop here if form is invalid
      this.$v.$touch();
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },
    successForm(route){
      Swal.fire("¡Operación exitosa!", "Información guardada con éxito", "success").then(() => {
       this.$router.push({
            path: route,
          });
      });
    },
    cancelForm(){
      this.$router.push({
        path: this.infoPage.routeBack,
      });
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },


};
</script>

<template>
  <Layout>
    <form  class="needs-validation" >
    <PageHeader :title="title" :items="items" />
    <div class="row">
      
      <div class="col-lg-12">
        <div id="addproduct-accordion" class="custom-accordion">
          <div class="card">
          
              <div class="p-4">
                <div class="media align-items-center">
                  <div class="me-3">
                    <div class="avatar-xs">
                      <div
                        class="avatar-title rounded-circle bg-soft-primary text-primary"
                      >
                        01
                      </div>
                    </div>
                  </div>
                  <div class="media-body overflow-hidden">
                    <h5 class="font-size-16 mb-1">Datos generales</h5>
                    <p class="text-muted text-truncate mb-0">
                      Completar información del pago
                    </p>
                  </div>
                 
                </div>
              </div>
            <b-collapse
              data-parent="#addproduct-accordion"
              id="accordion-1"
              visible
              accordion="my-accordion"
            >
              <div class="p-4 border-top">
                 <hr class="my-4" />

            <div class="row">
              
              <div class="col-sm-4">
                <div class="text-muted text-sm-end">
                  <div>
                    <h5 class="font-size-16 mb-1">Prestamista:</h5>
                    <p>{{infoData.client_name}}</p>
                  </div>
                  
                  <div class="mt-4">
                    <h5 class="font-size-16 mb-1">Cuenta:</h5>
                    <p>{{infoData.account_name}}</p>
                  </div>
                </div>
              </div>
              <div class="col-sm-4">
                 <div class="text-muted text-sm-end">
                  <div>
                    <h5 class="font-size-16 mb-1">Fecha de inicio:</h5>
                    <p>{{formatDate(objectData.start_date)}}</p>
                  </div>
                  <div  class="mt-4">
                    <h5 class="font-size-16 mb-1">Fecha de corte:</h5>
                    <p>{{formatDate(objectData.date)}}</p>
                  </div>
                  
            
                </div>
              </div>
              <div class="col-sm-4">
                <div class="text-muted text-sm-end">
                  <div>
                    <h5 class="font-size-16 mb-1">Total:</h5>
                    <p>{{formatMoney(objectData.amount)}}</p>
                  </div>
                  
                  <div class="mt-4">
                    <h5 class="font-size-16 mb-1">Moneda:</h5>
                    <p>{{objectData.currency}}</p>
                  </div>
                </div>
              </div>
            </div>
                  

                  <div class="row">
                    <div class="col-md-6">
                      <div class="mb-3">
                        <label for="name">Nombre(s) de quien recibió</label>
                        <input
                          id="name"
                        :disabled="infoPage.view === 'info'"
                          v-model="objectData.name"
                          type="text"
                          class="form-control"
                          placeholder="Nombre"
                          :class="{
                            'is-invalid': submitted && $v.objectData.name.$error,
                          }"
                        />
                        <div
                          v-if="submitted && $v.objectData.name.$error"
                          class="invalid-feedback">
                          <span v-if="!$v.objectData.name.required"
                            >Campo requerido</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                       <div class="mb-3">
                        <label for="name">Método de pago</label>
                         <select class="form-control select2" v-model="objectData.method_payment" 
                        :disabled="infoPage.view === 'info'">
                          <option :value="payment.id" v-for="payment in infoPage.method_payments" :key="payment.id" v-text="payment.text"></option>
                        </select>
                       </div>
                       
                      </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="mb-3 mb-0">
                        <label for="productdesc">Comentarios</label>
                        <textarea
                        v-model="objectData.comment"
                          class="form-control"
                          id="productdesc"
                          rows="4"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                    <div class="row">
                    <div class="col-md-12">
                        <div class="mb-1 mb-0">
                          <label for="productdesc">Firma</label>
                        </div>
                      <div class="container-signature mb-3">
                        <img :src="objectData.image" alt="" style="width:100%; height:300px" 
                        v-if="infoPage.view === 'info'">
                        <VueSignaturePad  width="100%" height="300px" ref="signaturePad" v-if="infoPage.view !== 'info'" />
                   
                        
                      </div>
                      <button   class="btn btn-info ms-1 float-right" @click.prevent="undo" v-if="infoPage.view === 'payment'">
                          <i class="uil uil-file-alt me-1"></i> Limpiar
                        </button>
                      </div>
                    </div>


                  
                  
              </div>
            </b-collapse>
          </div>

        
  
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col text-end ms-1">
        <a  class="btn btn-danger" @click.prevent="cancelForm()" >
          <i class="uil uil-times me-1"></i> Cancelar
        </a>
        <button   class="btn btn-success ms-1" @click.prevent="formSubmit" v-if="infoPage.view === 'payment'">
          <i class="uil uil-file-alt me-1"></i> Guardar
        </button>
      </div>
      <!-- end col -->
    </div>
    </form>
  </Layout>
</template>
